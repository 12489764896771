export const SIDER_WIDTH = 200;
export const COLLAPSED_SIDER_WIDTH = 48;
export const HEADER_HEIGHT = 58;
export const PAGE_TITLE_HEIGHT = 56;
export const TABLE_HEADER_HEIGHT = 55;
export const PAGINATION_HEIGHT = 64;
export const PAGINATION_BAR_HEIGHT = 64;
export const STICKY_ACTION_BAR_HEIGHT = 60;

// Z-index
export const Z_INDEX_APP_TOP_BAR = 10;
export const Z_INDEX_SMART_IMPORT_FOOTER = 5;
export const Z_INDEX_PDP_STICKY_FILTER = 3;
export const Z_INDEX_PDP_ACTION_TITLE = 2;
export const Z_INDEX_PDP_EDIT_TAG = 3;
export const Z_INDEX_PDP_CREATE_BOTOTM_BAR = 2;
export const Z_INDEX_PDP_TABLE = 1;
export const Z_INDEX_FILTER_DROPDOWN_SEARCH = 1;
export const Z_INDEX_PDP_FLOATING_MINI_TOP_HEADER = 10;
export const Z_INDEX_PIM_TABLE_PAGINATION = 1;
export const Z_INDEX_PIM_TABLE_ACTION_BAR = 10;
export const Z_INDEX_PIM_TABLE_DRAGGABLE_ROW = 9;
export const Z_INDEX_DAM_MODAL_SEARCHBAR = 2;
