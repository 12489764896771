import React from "react";
import { useTranslations } from "next-intl";
// import Link from "next/link";
import { UserOutlined } from "@ant-design/icons";
import { supplierRoutes } from "@/src/constants/routes";
import type { MenuProps } from "@/src/pattern-library";
import { Button, Dropdown, Modal } from "@/src/pattern-library";
import { segmentClient } from "@/src/utils/segment";
import { LogoutStatus } from "@/src/enums/authentication";
import { Namespace } from "@/src/i18n/namespace";
import Link from "next/link";
import { useFeatureFlag } from "@/src/hooks/useFeatureFlag";

const AppTopBar = () => {
  const t = useTranslations();
  const tCommon = useTranslations(Namespace.COMMON);
  const [modal, contextHolder] = Modal.useModal();
  const { isAccountSettingsEnabled } = useFeatureFlag();

  const handleSignOut = () => {
    segmentClient.logout(() => {
      window.location.href = `${supplierRoutes.logout}?status=${LogoutStatus.LOGGED_OUT}`;
    });
  };

  const items: MenuProps["items"] = [
    ...(isAccountSettingsEnabled
      ? [
          {
            key: "settings",
            label: (
              <Link href={supplierRoutes.settings.index}>
                {tCommon("navigation.accountSetting")}
              </Link>
            ),
          },
        ]
      : []),
    {
      key: "signOut",
      label: (
        <div
          onClick={() => {
            modal.confirm({
              title: t("common.navigation.modal.signOutTitle"),
              okText: t("global.button.signOut"),
              cancelText: t("global.button.cancel"),
              onOk: handleSignOut,
            });
          }}
        >
          {tCommon("navigation.signOut")}
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Dropdown menu={{ items }}>
        <Button shape="circle" icon={<UserOutlined />} />
      </Dropdown>
    </>
  );
};

export default AppTopBar;
